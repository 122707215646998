<template>
   <Header />
   <main>
      <section class="infoSection info-hero">
         <div class="container">
            <h1 class="info-hero__title hero__title">
               <span>Правовая</span>
               <span>информация</span>
            </h1>
            <div class="info-hero__docs">
               <div id="faq-question" class="faq-question">
                  <div class="faq-item" v-for="list in info" @click="list.active = !list.active" :key="list.name">
                     <div class="faq-item__title" :class="{ 'active': list.active }"><span>{{ list.name }}</span></div>
                     <div class="faq-item__subtitle" :class="{ 'styleObject': list.active }" v-html="list.disc">
                     </div>
                  </div>
                  <!--                   <div class="faq-item">
                     <div class="faq-item__title"><span>Пользовательское соглашение</span>
                     </div>
                     <div class="faq-item__subtitle">
                        Да, можно, но мы не несём ответственности за потерю или повреждение игрушек. Кроме того, в
                        лагере есть множество игр и развлечений, так что дети всегда найдут, чем заняться.
                     </div>
                  </div>
                  <div class="faq-item">
                     <div class="faq-item__title"><span>Свидетельство в Генеральном Реестре Туристических
                           Агентств</span>
                     </div>
                     <div class="faq-item__subtitle">
                        Да, можно, но мы не несём ответственности за потерю или повреждение игрушек. Кроме того, в
                        лагере есть множество игр и развлечений, так что дети всегда найдут, чем заняться.
                     </div>
                  </div>
                  <div class="faq-item">
                     <div class="faq-item__title"><span>Реквизиты ООО «Лидерлайф Турс»</span>
                     </div>
                     <div class="faq-item__subtitle">
                        Да, можно, но мы не несём ответственности за потерю или повреждение игрушек. Кроме того, в
                        лагере есть множество игр и развлечений, так что дети всегда найдут, чем заняться.
                     </div>
                  </div> -->
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<style type="text/css">
.styleObject {
   transition: 0.3s;
   display: block !important;
}

.faq-item__subtitle a {
   color: #7C57FC !important;
}
</style>

<script>
import axios from 'axios'
import Header from '@/components/Header/Header'

export default {
   name: 'Info',
   data() {
      return {
         info: [
            { name: 'Политика конфиденциальности', disc: 'Вы можете ознакомиться с политикой конфиденциальности скачав файл <a target="_blank" href="/policylider.pdf">здесь</a>', active: false },
            { name: 'Реестры', disc: '1. Генеральный реестр туроператоров: № РТО 013956 <a href="https://tourism.gov.ru/reestry/reestr-turoperatorov/show.php?id=110743" target="_blank">Проверить</a> <br> 2. <a href="/svidetelstvo.pdf" target="_blank">Свидетельство в Генеральном Реестре Туристических агенств</a> <br> 3. Реестр субъектов малого и среднего предпринимательства Москвы: № 7706-364951 <a href="https://ofd.nalog.ru/statistics.html?statDate=&level=0&fo=1&ssrf=77" target="_blank">Проверить</a> <br> 4. Единый реестр организации отдыха детей и их оздоровления. <a href="https://incamp.ru/bases/registry/?ysclid=lmgo02p4vz653557116" target="_blank">Проверить</a>', active: false },
            { name: 'Реквизиты компании', disc: '<a href="/requisites.pdf" target="_blank">Реквизиты Лидерлайф Турс</a>', active: false },
         ],

      }
   },
   methods: {

   },
   components: {
      Header
   }
}
</script>
